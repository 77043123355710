import utils from '../utils/api'
// 动态-广场
export function listTrends(data) {
  return utils({
    url: '/xznc/trends/page',
    method: "POST",
    data
  })
}

// 动态-添加
export function addTrends(data) {
  return utils({
    url: "/xznc/trends/add",
    method: "POST",
    data
  })
}

// 动态-修改
export function updateTrends(data) {
  return utils({
    url: "/xznc/trends/update",
    method: "POST",
    data
  })
}

// 动态-添加草稿
export function trendsDraftAdd(data) {
  return utils({
    url: "/xznc/trends/draft/save",
    method: "POST",
    data
  })
}

// 动态-添加草稿
export function delTrends(data) {
  return utils({
    url: "/xznc/trends/del",
    method: "POST",
    data
  })
}

// 动态-信息
export function trendsInfo(param) {
  return utils({
    url: '/xznc/trends/info/' + param,
    method: "GET"
  })
}

// 动态评论列表
export function getTrendsComment(params) {
  return utils({
    url: '/xznc/comment/page',
    method: "GET",
    params
  })
}

// 添加动态评论
export function addTrendsComment(data) {
  return utils({
    url: 'xznc/comment/add',
    method: "POST",
    data
  })
}

// 添加关注
export function addUserFollow(data) {
  return utils({
    url: 'xznc/user/follow/add/' + data,
    method: "POST"
  })
}

// 添加关注、点赞、转发记录
export function addTrendsStat(data) {
  return utils({
    url: 'xznc/info/stat/add',
    method: "POST",
    data
  })
}

// 查询关注、点赞、转发数
export function getTrendsStat(params) {
  return utils({
    url: 'xznc/info/stat/detail',
    method: "GET",
    params
  })
}

// 取消动态的 关注、点赞
export function cancelTrendsStat(data) {
  return utils({
    url: 'xznc/info/stat/del',
    method: "POST",
    data
  })
}

// 草稿列表
export function getTrendsDraft(params) {
  return utils({
    url: 'xznc/trends/draft/page',
    method: "get",
    params
  })
}


