<template>
  <div class="setBox">
    <div class="boxTop">
      <div class="topLeft" @click="goBack()"></div>
      <div class="topCon">评论</div>
    </div>
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        offset="800"
        @load="onLoad"
      >
    <div class="container" v-for="item in commentList" :key="item.id">
      <div class="contentHearName">
        <div class="contentHear" @click="gotrends()"> <img :src="item.headImg" alt=""> </div>
        <div class="contentNameTime">
          <div>{{item.nickName}}</div>
          <div>{{item.createTime}}</div>
        </div>
        <div class="contenttHf">
          <span @click="hfEvent(item)">回复</span>
          <img src="../../image/quanz/gd.png" alt="" @click="showPopup(item)" />
        </div>
      </div>
      <div class="current">{{item.content}}</div>
      <div class="contentBox" @click="goTrendsDetail(item)">
        <div>
          {{ item.infoContent }}
        </div>
      </div>
    </div>
      </van-list>
    </van-pull-refresh>
    <van-popup v-model="show" round position="bottom">
      <div class="popupDiv" @click="lhEveent()">拉黑</div>
<!--      <div class="popupDiv1">举报/投诉</div>-->
      <div class="line"></div>
      <div class="popupDiv1" @click="show = false">取消</div>
    </van-popup>
        <van-overlay :show="showOver" @click="showOver = false">
      <div class="wrapper" @click.stop>
        <div class="block">
          <div class="blockTop">将“低调的先生”移除黑名单</div>
          <div class="blockContent">
            移除后对方将可以关注你、评论或点赞你的圈子、向你发私信等。
          </div>
          <div class="blockBtn">
            <div @click="showOver = false">取消</div>
            <div @click="removeEvent()">确定</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <!-- 回复 -->
    <van-popup v-model="show1" round position="bottom">
      <div class="footerTop">
        <div class="footerLeft">
          <img src="../../image/xpl.png" alt />
          <input
            type="text"
            v-model="commentForm.content"
            placeholder="写个评论吧~"
          />
        </div>
        <span @click="addEvent()">发送</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { businessComments , blackAdd} from "@/api/circle";
import { addTrendsComment } from "@/api/trends";
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      show1: false,
      showOver: false,
      commentList:[],
      black:{
        userId:''
      },
      commentForm:{
        content:'',
        parentId:'',
        infoId:'',
        business: 2,
      },
      pageNum: 1,
      pageSize: 10,
      total: 0,
      isLoading: false,
      loading: false, //上拉加载
      finished: false, //加载与不加载的状态
    };
  },
  mounted() {
    this.getBusinessComments()
  },
  methods: {
    getBusinessComments(){
      businessComments({
        business:2,
        pageNum: this.pageNum,
        pageSize:this.pageSize
      }).then(res=>{
        this.commentList = this.commentList.concat(res.data.data.items);
        if (this.commentList.length >= res.data.data.total) {
          this.finished = true;
        }
      })
    },
    goBack() {
      this.$router.go(-1);
    },
    // 评论回复
    hfEvent(item) {
      this.show1 = true
      this.commentForm.content = ''
      console.log(item);
      this.commentForm.infoId = item.infoId;
      this.commentForm.parentId = item.id
    },
    // 回复
    addEvent(){
      addTrendsComment(this.commentForm).then(res=>{
        console.log(res,'回复评论');
        if(res.data.code === 0){
          this.$toast("评论成功")
          this.show1 = false
          this.getBusinessComments()
        }
      })
    },
    showPopup(item) {
      this.show = true;
      this.black.userId = item.userId
    },
     // 拉黑弹框
    lhEveent() {
      this.showOver = true;
      this.show = false;
    },
      // 确定拉黑
    removeEvent() {
      this.show = false;
      blackAdd(this.black).then(res=>{
        console.log(res,'拉黑');
        if(res.data.code === 0){
          this.showOver = false;
          this.commentList = [];
          this.getBusinessComments()
        }
      })

    },
    // 跳转动态
    gotrends() {
      this.$router.push({ path: "/TrendIndex" });
    },
     // 跳转详情
    goTrendsDetail(item) {
      console.log(item);
      this.$router.push({ path: "/trendsDetail", query: {id: item.infoId} });
    },
    onRefresh() {
      this.pageNum = 1;
      this.commentList = [];
      this.getBusinessComments();
    },
    onLoad() {
      this.pageNum ++;
      this.getBusinessComments();
    }
  },
};
</script>

<style scoped>
.boxTop {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 60px;
}
.topLeft {
  width: 48px;
  height: 48px;
  background-image: url("../../image/back.png");
  background-size: 100% 100%;
  margin-left: 32px;
}
.topCon {
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  margin-left: 256px;
}
.container {
  margin-left: 32px;
}
.contentHearName {
  display: flex;
  align-items: center;
}

.contentHear img{
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.contentNameTime {
  width: 440px;
  margin-left: 20px;
}
.contentNameTime div:nth-child(1) {
  font-size: 28px;
  font-weight: 600;
  color: #0b1526;
}
.contentNameTime div:nth-child(2) {
  font-size: 24px;
  font-weight: 400;
  color: #97a4b4;
  margin-top: 6px;
}
.contenttHf {
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  display: flex;
  align-items: center;
}
.contenttHf img {
  width: 40px;
  height: 40px;
  margin-left: 32px;
}
.current {
  display: flex;
  font-size: 28px;
  font-weight: 400;
  color: #0b1526;
  margin-top: 32px;
}
.contentBox {
  width: 686px;
  /* height: 176px; */
  background: #f6f8fa;
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin-top: 38px;
  overflow: hidden;
  margin-bottom: 80px;
}

.contentBox div {
  width: 622px;
  font-size: 28px;
  font-weight: 400;
  color: #97a4b4;
  margin-left: 32px;
  margin-top: 16px;
  line-height: 46px;
  padding-bottom:8px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.popupDiv,
.popupDiv1 {
  width: 750px;
  height: 104px;
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 104px;
  border-bottom: 1px solid #f6f8fa;
}
.popupDiv1 {
  border: none;
}
.line {
  width: 750px;
  height: 20px;
  background: #f6f8fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.block {
  width: 510px;
  height: 346px;
  background: #ffffff;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
}
.blockTop {
  width: 510px;
  height: 88px;
  border-bottom: 1px solid #f6f8fa;
  font-size: 32px;
  font-weight: 600;
  color: #0b1526;
  text-align: center;
  line-height: 88px;
}
.blockContent {
  font-size: 28px;
  width: 446px;
  font-weight: 400;
  color: #0b1526;
  margin-left: 32px;
  margin-top: 20px;
}
.blockBtn {
  display: flex;
  margin-top: 32px;
}
.blockBtn div:nth-child(1) {
  width: 160px;
  height: 84px;
  background: #f6f8fa;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #0b1526;
  text-align: center;
  line-height: 84px;
  margin-left: 32px;
}
.blockBtn div:nth-child(2) {
  width: 262px;
  height: 84px;
  background: #0957c3;
  border-radius: 12px 12px 12px 12px;
  opacity: 1;
  font-size: 32px;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  line-height: 84px;
  margin-left: 24px;
}
.footerTop {
  display: flex;
  align-items: center;
    margin-bottom:32px;
  margin-top:32px;
}
.footerLeft {
  width: 610px;
  height: 72px;
  background: #f6f8fa;
  border-radius: 16px;
  opacity: 1;
  margin-left: 32px;
  display: flex;
  align-items: center;
}
.footerLeft img {
  width: 28px;
  height: 28px;
  margin-left: 24px;
}
.footerLeft input {
  outline: none;
  width: 500px;
  border: none;
  font-size: 28px;
  background: #f7f8fa;
  margin-left: 8px;
}
.footerTop span {
  display: inline-block;
  margin-right: 5px;
  font-size: 24px;
  font-weight: 500;
  color: #0957c3;
  margin-left: 28px;
}
</style>
